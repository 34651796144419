<template>
    <EditShippingMethod />
</template>

<script>
import {defineComponent, onMounted} from "vue";
import EditShippingMethod from "../components/EditShippingMethod";
import {setPageHeader} from "../../../../core/helpers/toolbar";

export default defineComponent({
    components: {
        EditShippingMethod
    },
    setup() {
        onMounted(() => {
            setPageHeader({
                title: 'message.EDIT message.SHIPPING_METHODS',
                actionButton: null,
                breadCrumbs: [
                    { name: "message.HOME", link: "/dashboard"},
                    { name: "message.SYSTEM", link: "#"},
                    { name: "message.SETTINGS", link: "/system/settings"},
                    { name: "message.SHIPPING_METHODS", link: "/system/settings/shipping-methods"},
                    { name: "message.EDIT message.SHIPPING_METHODS"}
                ]
            })
        });
    }
});
</script>