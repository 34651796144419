
import { defineComponent, ref, inject, watchEffect, onMounted } from 'vue';
import { Apollo, Notify } from '@/core/services';
import { UPDATE_SHIPPING_METHOD } from '@/modules/system/shipping-methods/graphql/Mutations';
import { useStore } from 'vuex';
import { EDIT_SHIPPING_METHOD } from '@/modules/system/shipping-methods/graphql/Queries';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import ShippingMethodSites from '../../shipping-methods/components/partials/ShippingMethodSites.vue';
import ShippingMethodCustomerGroups from '../../shipping-methods/components/partials/ShippingMethodCustomerGroups.vue';
import ShippingMethodCustomers from '../../shipping-methods/components/partials/ShippingMethodCustomers.vue';
import ShippingMethodBasicInfo from '../../shipping-methods/components/partials/ShippingMethodBasicInfo.vue';
import ShippingMethodPostCodes from '../../shipping-methods/components/partials/ShippingMethodPostCodes.vue';
import ShippingMethodIntegration from '../../shipping-methods/components/partials/ShippingMethodIntegration.vue';
import InnerLoader from '../../../../components/InnerLoader.vue';

export default defineComponent({
    name: 'add new shipping method',
    components: {
        ShippingMethodSites,
        ShippingMethodCustomerGroups,
        ShippingMethodCustomers,
        ShippingMethodBasicInfo,
        ShippingMethodPostCodes,
        ShippingMethodIntegration,
        InnerLoader
    },

    setup() {
        const route = useRoute();
        const loading = ref(false);
        const emitter: any = inject('emitter');
        const addShippingMethodForm = ref<null | HTMLFormElement>(null);
        const sitesFormData = ref<null | HTMLFormElement>(null);
        const customerGroupFormData = ref<null | HTMLFormElement>(null);
        const customerFormData = ref<null | HTMLFormElement>(null);
        const integrationFormData = ref<null | HTMLFormElement>(null);
        const basicFormData = ref<null | HTMLFormElement>(null);
        const postCodeFormData = ref<null | HTMLFormElement>(null);
        const stepValidate = ref({
            basicFormRef: false,
            siteFormRef: false,
            customerGroupFormData: false,
            customersFormData: false,
            postCodeFormData: false,
            integrationFormData: false
        }) as Record<any, any>;
        const sites = ref([]) as Record<any, any>;
        const store = useStore();
        const loader = ref(false);
        const imageLabel = ref('');
        const renderCartOption = ref([]);
        const router = useRouter();
        const i18n = useI18n();
        const activeTab = ref('first');
        const customerGroupRules = ref([]) as Record<any, any>;
        const shippingLabel = ref([]) as Record<any, any>;
        const postCodeRules = ref([]) as Record<any, any>;

        // Given Shipping Form Interface
        const shippingMethod = ref({
            basicFormData: {
                name: '',
                sub_title: '',
                status: false,
                jsonNameFields: ref({}) as Record<any, any>,
                jsonSubTitleFields: ref({}) as Record<any, any>,
                images: ref([]) as Record<any, any>
            },
            shipping_method_id: '',
            sitesFormData: {
                rule: [],
                cartRule: [],
                site_cost_no_rule: [],
                site_tax_no_rule: [],
                sites: [],
                site_cart_rules: [],
                site_cart_rules2: [],
                site_cart_cost: [],
                site_cart_cost2: [],
                site_tax: [],
                site_tax2: [],
                cartRulesData: {},
                noRuleCost: {}
            },
            customerGroupFormData: {
                rule: [],
                cartRule: [],
                customer_group_cost_no_rule: [],
                customer_group_tax_no_rule: [],
                customer_groups: [],
                customer_group_cart_rules: [],
                customer_group_cart_rules2: [],
                customer_group_cart_cost: [],
                customer_group_cart_cost2: [],
                customer_group_tax: [],
                customer_group_tax2: [],
                cartRulesData: {},
                noRuleCost: {}
            },
            customersFormData: {
                rule: [],
                cartRule: [],
                customers: [],
                customer_cart_rules: [],
                customer_cart_rules2: [],
                customer_cart_cost: [],
                customer_cart_cost2: [],
                customer_tax: [],
                customer_tax2: [],
                customer_cost_no_rule: [],
                customer_tax_no_rule: [],
                cartRulesData: {},
                noRuleCost: {}
            },
            postCodeFormData: {
                rule: [],
                cartRule: [],
                post_code_cost_no_rule: [],
                post_code_tax_no_rule: [],
                post_codes: [],
                post_code_cart_rules: [],
                post_code_cart_rules2: [],
                post_code_cart_cost: [],
                post_code_cart_cost2: [],
                post_code_tax: [],
                post_code_tax2: [],
                cartRulesData: {},
                noRuleCost: {}
            },
            integrationFormData: {
                carriers: '',
                shipping_method: '',
                templates: '',
                types: '',
                address: 'Mesterlodden 42, 2820 Gentofte',
                is_pickup: 0,
                template_data: ''
            }
        });

        watchEffect(() => {
            sites.value = [];
            const getSites = store.getters.getAllSites?.data?.sites;
            getSites.forEach((element, index) => {
                sites.value.push({
                    label: element?.name,
                    value: element?.id,
                    index: index
                });
            });
        });

        const stepLogValidate = (action: any = null) => {
            return action == null ? stepValidate.value : (stepValidate.value[action] = true);
        };

        // Submit handler Request
        const submitHandlerForm = async () => {
            addShippingMethodForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    basicFormData.value?.validateForm();
                    sitesFormData.value?.validateForm();
                    customerGroupFormData.value?.validateForm();
                    customerFormData.value?.validateForm();
                    const validateIntegration = integrationFormData.value?.validateForm();
                    postCodeFormData.value?.validateForm();

                    const validate = stepLogValidate();
                    if (
                        validate.basicFormData == true &&
                        validate.sitesFormData == true &&
                        validate.customersFormData == true &&
                        validate.customerGroupFormData == true &&
                        validate.postCodeFormData == true &&
                        validateIntegration == true
                    ) {
                        for (const i in shippingMethod?.value.basicFormData?.jsonNameFields) {
                            if (shippingMethod.value?.basicFormData?.jsonNameFields[i]?.name == '') {
                                shippingMethod.value.basicFormData.jsonNameFields[i].name = shippingMethod.value?.basicFormData?.name;
                            }
                        }
                        for (const i in shippingMethod?.value.basicFormData?.jsonSubTitleFields) {
                            if (shippingMethod.value?.basicFormData?.jsonSubTitleFields[i]?.name == '') {
                                shippingMethod.value.basicFormData.jsonSubTitleFields[i].name = shippingMethod.value?.basicFormData?.sub_title;
                            }
                        }

                        if (shippingMethod.value?.basicFormData?.images?.length == 0) {
                            Notify.error(`${i18n.t('message.IMAGE')} ${i18n.t('message.IS_REQUIRED')}`);
                            return;
                        }

                        const cartRulesData = {
                            sites: {
                                greater_condition: {
                                    rules: shippingMethod.value?.sitesFormData?.site_cart_rules,
                                    cost: shippingMethod.value?.sitesFormData?.site_cart_cost,
                                    tax: shippingMethod.value?.sitesFormData?.site_tax
                                },
                                less_condition: {
                                    rules: shippingMethod.value?.sitesFormData?.site_cart_rules2,
                                    cost: shippingMethod.value?.sitesFormData?.site_cart_cost2,
                                    tax: shippingMethod.value?.sitesFormData?.site_tax2
                                }
                            },
                            customer_groups: {
                                greater_condition: {
                                    rules: shippingMethod.value?.customerGroupFormData?.customer_group_cart_rules,
                                    cost: shippingMethod.value?.customerGroupFormData?.customer_group_cart_cost,
                                    tax: shippingMethod.value?.customerGroupFormData?.customer_group_tax
                                },
                                less_condition: {
                                    rules: shippingMethod.value?.customerGroupFormData?.customer_group_cart_rules2,
                                    cost: shippingMethod.value?.customerGroupFormData?.customer_group_cart_cost2,
                                    tax: shippingMethod.value?.customerGroupFormData?.customer_group_tax2
                                }
                            },
                            customers: {
                                greater_condition: {
                                    rules: shippingMethod.value?.customersFormData?.customer_cart_rules,
                                    cost: shippingMethod.value?.customersFormData?.customer_cart_cost,
                                    tax: shippingMethod.value?.customersFormData?.customer_tax
                                },
                                less_condition: {
                                    rules: shippingMethod.value?.customersFormData?.customer_cart_rules2,
                                    cost: shippingMethod.value?.customersFormData?.customer_cart_cost2,
                                    tax: shippingMethod.value?.customersFormData?.customer_tax2
                                }
                            },
                            post_codes: {
                                greater_condition: {
                                    rules: shippingMethod.value?.postCodeFormData?.post_code_cart_rules,
                                    cost: shippingMethod.value?.postCodeFormData?.post_code_cart_cost,
                                    tax: shippingMethod.value?.postCodeFormData?.post_code_tax
                                },
                                less_condition: {
                                    rules: shippingMethod.value?.postCodeFormData?.post_code_cart_rules2,
                                    cost: shippingMethod.value?.postCodeFormData?.post_code_cart_cost2,
                                    tax: shippingMethod.value?.postCodeFormData?.post_code_tax2
                                }
                            }
                        };

                        const noRuleCost = {
                            site_cost: JSON.stringify(shippingMethod.value?.sitesFormData?.site_cost_no_rule),
                            site_tax: JSON.stringify(shippingMethod.value?.sitesFormData?.site_tax_no_rule),
                            customer_group_cost: JSON.stringify(shippingMethod.value?.customerGroupFormData?.customer_group_cost_no_rule),
                            customer_group_tax: JSON.stringify(shippingMethod.value?.customerGroupFormData?.customer_group_tax_no_rule),
                            customer_cost: JSON.stringify(shippingMethod.value?.customersFormData?.customer_cost_no_rule),
                            customer_tax: JSON.stringify(shippingMethod.value?.customersFormData?.customer_tax_no_rule),
                            post_code_cost: JSON.stringify(shippingMethod.value?.postCodeFormData?.post_code_cost_no_rule),
                            post_code_tax: JSON.stringify(shippingMethod.value?.postCodeFormData?.post_code_tax_no_rule)
                        };

                        const formData = {
                            id: shippingMethod.value?.shipping_method_id,
                            name: JSON.stringify(shippingMethod.value?.basicFormData?.jsonNameFields),
                            sub_title: JSON.stringify(shippingMethod.value?.basicFormData?.jsonSubTitleFields),
                            no_rule_cost: JSON.stringify(noRuleCost),
                            sites: shippingMethod.value?.sitesFormData?.sites,
                            status: shippingMethod.value?.basicFormData?.status == false ? 0 : 1,
                            rules: shippingMethod.value?.sitesFormData?.rule,
                            cart_rule: shippingMethod.value?.sitesFormData?.cartRule,
                            customer_group_rule: shippingMethod.value?.customerGroupFormData?.rule,
                            customer_group_cart_option_value: shippingMethod.value?.customerGroupFormData?.cartRule,
                            customer_groups: shippingMethod.value?.customerGroupFormData?.customer_groups,
                            customer_rule: shippingMethod.value?.customersFormData?.rule,
                            customer_cart_option_value: shippingMethod.value?.customersFormData?.cartRule,
                            customers: shippingMethod.value?.customersFormData?.customers,
                            cart_rules_data: JSON.stringify(cartRulesData),
                            image: shippingMethod.value?.basicFormData?.images[0]?.id,
                            post_code_rule: shippingMethod.value?.postCodeFormData?.rule,
                            post_code_cart_option_value: shippingMethod.value?.postCodeFormData?.cartRule,
                            post_codes: shippingMethod.value?.postCodeFormData?.post_codes,
                            carriers: shippingMethod.value.integrationFormData.carriers,
                            shipping_method: shippingMethod.value.integrationFormData.shipping_method,
                            templates: shippingMethod.value.integrationFormData.templates != '' ? shippingMethod.value.integrationFormData.templates : 0,
                            types: shippingMethod.value.integrationFormData.types,
                            address: shippingMethod.value.integrationFormData.address,
                            is_pickup: shippingMethod.value.integrationFormData.is_pickup,
                            template_data: shippingMethod.value.integrationFormData.template_data
                        };
                        loader.value = true;
                        await Apollo.mutate({
                            mutation: UPDATE_SHIPPING_METHOD,
                            variables: { input: formData },
                            update: (store, { data: { create_shipping_method } }) => {
                                loader.value = false;
                                Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                                router.push({
                                    name: 'shipping-methods'
                                });
                            }
                        }).catch(e => {
                            loader.value = false;
                        });
                    } else {
                        validate.basicFormData = false;
                        validate.sitesFormData = false;
                        validate.customersFormData = false;
                        validate.customerGroupFormData = false;
                        validate.postCodeFormData = false;
                        validate.integrationFormData = false;
                    }
                }
            });
        };

        // Reset Form Data On Model Popup
        const resetForm = () => {
            imageLabel.value = '';
            renderCartOption.value = [];
            emitter.emit('clearInput');
            // empty json fields data
            addShippingMethodForm.value?.resetFields();
        };

        const editShippingMethod = () => {
            loader.value = true;
            resetForm();
            Apollo.watchQuery({
                query: EDIT_SHIPPING_METHOD,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only',
                errorPolicy: 'all',
                variables: {
                    shipping_method_id: route?.params?.uuid
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }

                const shippingData = JSON.parse(data?.edit_shipping_method);
                shippingMethod.value.shipping_method_id = shippingData?.id;

                activeTab.value = 'first';
                basicFormData.value?.editShippingMethodValue(shippingData);
                sitesFormData.value?.editShippingMethodValue(shippingData);
                customerGroupFormData.value?.editShippingMethodValue(shippingData);
                customerFormData.value?.editShippingMethodValue(shippingData);
                postCodeFormData.value?.editShippingMethodValue(shippingData);
                integrationFormData.value?.editShippingMethodValue(shippingData);
                loader.value = false;
            });
        };

        const dataHandler = (action: any, data: Record<any, any>) => {
            shippingMethod.value[action] = data;
            stepLogValidate(action);
        };

        onMounted(() => {
            editShippingMethod();
        });

        return {
            loading,
            shippingMethod,
            addShippingMethodForm,
            sites,
            imageLabel,
            renderCartOption,
            customerGroupRules,
            shippingLabel,
            activeTab,
            sitesFormData,
            customerGroupFormData,
            customerFormData,
            basicFormData,
            postCodeRules,
            postCodeFormData,
            integrationFormData,
            loader,
            dataHandler,
            resetForm,
            submitHandlerForm
        };
    }
});
